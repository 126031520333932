<template>
  <div id="page-wrapper">
    <Breadcrumbs :crumbs="pageCrumbs" />
    <PageContent />
  </div>
</template>

<script>
import { mapState, useStore } from 'vuex';
import { getMetaTags, getTitlePropByMetadata } from '../../../helpers/metadata';
import PageContent from '../../../components/PageContent.vue';
import Breadcrumbs from '../../../components/global/Breadcrumbs.vue';

export default {
  name: 'Page',
  components: {
    Breadcrumbs,
    PageContent,
  },
  setup() {
    definePageMeta({
      middleware: ['password-protected'],
      layout: 'default',
    });
    onMounted(() => {
      if (useRuntimeConfig().public.IS_INTRANET) {
        setPageLayout('intranet');
      }
    });

    const store = useStore();
    const metainfo = {
      metaTitle: getTitlePropByMetadata(store.state.page.metadata, store.state.page.name),
      metaTags: getMetaTags(store.state.page.metadata),
    };
    useHead({
      ...metainfo.metaTitle,
      meta: metainfo.metaTags,
    });
  },
  data() {
    return {
      pageCrumbs: [],
      metadata: {},
    };
  },
  computed: {
    ...mapState(['page', 'globals', 'pathPrefix']),
  },
  mounted() {
    this.getBreadcrumbs();
    if (window.location.hash && window.location.hash === this.$route.hash) {
      const el = document.getElementById(this.$route.hash.slice(1));
      if (el) {
        window.scrollTo(0, el.offsetTop);
      }
    }
  },
  methods: {
    getBreadcrumbs() {
      this.pageCrumbs.push({
        name: this.globals.info.school_name,
        link: `${this.pathPrefix}/`,
      });
      if (!this.page.breadcrumbs) {
        return;
      }

      this.page.breadcrumbs.forEach((breadcrumb) => {
        const link = breadcrumb.slug ? `${this.pathPrefix}/page/${breadcrumb.slug}` : '';
        this.pageCrumbs.push({ name: breadcrumb.name, link });
      });
    },
  },
};
</script>
